<template>
  <div>
    <v-select
      outlined
      v-model="period"
      :items="periods"
      item-text="name"
      item-value="id"
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Pour la période"
    ></v-select>
  </div>
</template>

<script>
import { URL_PARAM_NAMES } from "@/utils/constants";
import { getDateStr, ONE_DAY_MICROTIME } from "@/utils/dates";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

const TODAY = new Date();
const YESTERDAY = new Date(TODAY.getTime() - 1 * ONE_DAY_MICROTIME);

export default {
  name: "PeriodFilter",
  data() {
    return {
      periods: [
        {
          id: 30,
          name: "30 derniers jours",
        },
        {
          id: 91,
          name: "3 derniers mois",
        },
        {
          id: 182,
          name: "6 derniers mois",
        },
        {
          id: 365,
          name: "12 derniers mois",
        },
      ],
    };
  },
  async created() {
    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: "marmiton/updateMarmitonPeriod",
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.period,
      // is_multiple: false,
      is_integer: true,
      // is_boolean: false,
      // dependsOn: undefined,
    });
  },
  computed: {
    period: {
      get() {
        return this.$store.getters["marmiton/getMarmitonPeriod"];
      },
      set(e) {
        this.$store.dispatch("marmiton/updateMarmitonPeriod", e);

        const endDate = YESTERDAY;
        const startDate = new Date(YESTERDAY.getTime() - e * ONE_DAY_MICROTIME);
        this.dates = [getDateStr(endDate), getDateStr(startDate)];
        this.$store.dispatch("marmiton/updateMarmitonDates", this.dates);
      },
    },
  },
  watch: {
    period(newValue) {
      addQueryStringParam(
        this.$router,
        this.$route,
        URL_PARAM_NAMES[this.$options.name],
        newValue
      );
    },
  },
};
</script>

<style></style>
