<template>
  <div>
    <v-autocomplete
      v-model="storedIngredients"
      :items="items"
      item-text="name"
      item-value="name"
      :search-input.sync="search"
      v-on:keyup="debouncedGetData"
      multiple
      clearable
      dense
      hide-details
      outlined
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      :label="label"
    ></v-autocomplete>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import axios from "@axios";

import { ingredientGroupAutoCompleteValue } from "@/components/regie/common/utils/ingredientGroup.js";

import { runParallelAsyncs } from "@/utils/async";
import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

export default {
  name: "IngredientFilter",
  props: {
    label: {
      type: String,
      default: "Ingrédients",
    },
    withGroups: {
      type: Boolean,
      default: true,
    },
    store: {
      type: String,
      default: "marmiton",
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return ["marmiton"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getMarmitonIngredientsToInclude",
    },
    storeUpdater: {
      type: String,
      default: "updateMarmitonIngredientsToInclude",
    },
    toExclude: {
      type: Boolean,
      default: false,
    },
    anchor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      search: "",
      items: [],
    };
  },
  async created() {
    await this.getData();

    this.initialUrlValue = await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: `${this.store}/${this.storeUpdater}`,
      param: this.toExclude
        ? URL_PARAM_NAMES["IngredientToExcludeFilter"]
        : URL_PARAM_NAMES[this.$options.name],
      value: this.storedIngredients,
      is_multiple: true,
      // is_integer: false,
      // is_boolean: false,
      // dependsOn: undefined,
    });
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const {
        ingredientGroupsResponse: {
          data: { items: ingredientGroupsData },
        },
        ingredientsResponse: { data: ingredientsData },
      } = await runParallelAsyncs({
        ingredientGroupsResponse: axios.get(
          "/regie/marmiton/ingredient-groups",
          {
            params: { search_text: this.search },
          }
        ),
        ingredientsResponse: axios.get("/regie/marmiton/ingredients", {
          params: { search_text: this.search },
        }),
      });

      this.items = this.storedIngredients
        .concat(
          this.withGroups
            ? ingredientGroupsData.map((ingredientGroup) => ({
                name: ingredientGroupAutoCompleteValue(ingredientGroup),
              }))
            : []
        )
        .concat(ingredientsData);
    },
  },
  computed: {
    storedIngredients: {
      get() {
        return this.$store.getters[`${this.store}/${this.storeGetter}`];
      },
      set(e) {
        this.$store.dispatch(`${this.store}/${this.storeUpdater}`, e);
      },
    },
  },
  watch: {
    storedIngredients(newValue) {
      addQueryStringParam(
        this.$router,
        this.$route,
        this.toExclude
          ? URL_PARAM_NAMES["IngredientToExcludeFilter"]
          : URL_PARAM_NAMES[this.$options.name],
        newValue,
        this.anchor
      );
    },
  },
};
</script>

<style></style>
